@import "../../../styles/base/vars";

.loader-page {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 70vh;
  min-height: calc(100vh - 218px);
  padding: 25px;
  text-align: center;
  @media screen and (max-width: $laptop) {
    height: calc(100vh - 169px);
  }
  @media screen and (max-height: $tablet-small) {
      min-height: calc(100vh - 124px);
      height: auto;
  }
  @media screen and (max-width: $smartphone) {
      height: auto;
      min-height: calc(100vh - 245px);
  }
  @media screen and (max-width: $smartphoneSmall) {
      min-height: calc(100vh - 260px);
      height: auto;
  }
  img {
    width: 50px;
    height: 50px;
  }
}