@import "../../../styles/base/vars";

.main-page {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    color: #FFF;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 80vh;
    min-height: calc(100vh - 83px);
    height: 380px;
    padding: 25px;
    text-align: center;
    @media screen and (max-width: $tablet) {
        min-height: calc(100vh - 91px);
        height: auto;
    }
    h1 {
        font-size: 42px;
        font-weight: bold;
        margin-top: 12px;
        letter-spacing: -0.5px;
        @media screen and (max-width: $laptop) {
            margin-top: 6px;
            letter-spacing: -1.3px;
        }
        @media screen and (max-width: $smartphone) {
            font-size: 32px;
        }
    }
    p {
        font-size: 20px;
        font-weight: normal;
        font-style: italic;
        line-height: 1.71;
        @media screen and (max-width: $laptop) {
            font-size: 14px;
        }
    }
    .logo {
        width: 155px;
        @media screen and (max-width: $laptop) {
            width: 105px;
        }
        @media screen and (max-width: $smartphoneSmall) {
            width: 130px;
        }
    }
    .custom-btn {
        margin-top: 37px;
        min-width: 180px;
        @media screen and (max-width: $laptop) {
            margin-top: 24px;
        }
    }
}