body {
    padding: 10px;
    @media screen and (max-width: $tablet) {
        padding: 0;
    }
    .main-page .logo {
        filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='12');
    }
    &.ReactModal__Body--open {
        overflow: hidden;
        > #root {
            filter: url("data:image/svg+xml;utf9,<svg%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'><filter%20id='blur'><feGaussianBlur%20stdDeviation='3'%20/></filter></svg>#blur");
            filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='3'); 
            filter: blur(12px);
            -webkit-filter : blur(12px);
            -moz-filter : blur(12px);
            -o-filter : blur(12px);
            -ms-filter : blur(12px);
        }
    }
    &.ReactModal__Body--before-close > #root {
        filter: none;
        -webkit-filter: none;
        filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
    }
    #root {
        background-image: url('../assets/images/bg.jpg');
        background-color: #00732e;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        width: 100%;
        overflow-y: scroll;
        transition: all var(--transition-time-fast) var(--timing-f);
        filter: none;
    }
    .App > div {
        min-height: 90vh;
        min-height: calc(100vh - 20px);
        @media screen and (max-height: $smartphone) {
            padding: 60px 0;
        }
        @media screen and (max-width: $tablet) {
            padding: 0;
            min-height: calc(100vh - 65px);
        }
        @media screen and (max-width: $smartphone) {
            min-height: calc(100vh - 91px);
        }
    }
}
.header {
    left: 0;
    top: 0;
    padding: 41px 36px;
    position: absolute;
    width: 100%;
    z-index: 2;
    @media screen and (max-width: $laptop) {
        padding: 25px 25px 0 25px;
    }
    @media screen and (max-height: $smartphoneHorizontal) {
        padding: 20px 25px 0 25px;
    }
    @media screen and (max-width: $tablet) {
        padding: 25px;
    }
    @media screen and (max-width: $tablet) {
        position: relative;
    }
    .logo {
        display: inline-block;
        position: relative;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
        z-index: 1;
        &:hover {
            opacity: .7;
        }
        img {
            width: 63px;
            @media screen and (max-width: $laptop) {
                width: 43px;
            }
        }
    }
    .steps {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        min-width: 150px;
        width: 70%;
        width: calc(100% - 275px);
        @media screen and (max-width: $laptop) {
            margin-top: 15px;
        }
        @media screen and (max-width: $tablet) {
            margin: 0;
        }
        .btn {
            background: none;
            border: none;
            color: rgba(255,255,255, 1);
            cursor: pointer;
            font-size: 33px;
            margin: 0 3px;
            padding: 0;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
            &:hover {
                color: rgba(255,255,255, .7);
                @media screen and (max-width: $tablet) {
                    color: rgba(255,255,255, 1);
                }
            }
            &:disabled {
                cursor: default;
            }
            @media screen and (max-width: $laptop) {
                font-size: 15px;
                margin: 0 1px;
            }
            @media screen and (max-width: $tablet) {
                font-size: 20px;
            }
        }
        .step-item {
            background: #9ef800; /* Old browsers */
            background: -moz-linear-gradient(top, #9ef800 0%, #b0fd2a 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #9ef800 0%,#b0fd2a 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #9ef800 0%,#b0fd2a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9ef800', endColorstr='#b0fd2a',GradientType=0 ); /* IE6-9 */
            -webkit-box-shadow: 11.3px 11.3px 16px 0 rgba(0, 0, 0, 0.08);
            -moz-box-shadow: 11.3px 11.3px 16px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 11.3px 11.3px 16px 0 rgba(0, 0, 0, 0.08);
            border-radius: 50%;
            color: #004b25;
            font-size: 20px;
            font-weight: bold;
            line-height: 0.56;
            text-align: center;
            text-indent: -9999px;
            overflow: hidden;
            margin: 0 5px;
            height: 8px;
            opacity: .3;
            width: 8px;
            @media screen and (max-width: $laptop) {
                font-size: 12px;
                height: 5px;
                margin: 0 2px;
                width: 5px;
            }
            @media screen and (max-width: $tablet) {
                display: none;
            }
        }
        .step-mob {
            display: none;
            @media screen and (max-width: $tablet) {
                display: block;
                color: #ffffff;
                font-size: 16px;
                line-height: 20px;
                height: 20px;
                font-weight: 600;
                padding: 0 10px;
                span {
                    font-weight: normal;
                }
            }

        }
        .step-item.passed {
            opacity: 1;
        }
        .step-item.active {
            background: #FFF;
            opacity: 1;
            line-height: 32px;
            height: 32px;
            text-indent: 1px;
            width: 32px;
            @media screen and (max-width: $laptop) {
                height: 22px;
                line-height: 22px;
                width: 22px;
            }
        }
    }
}
.custom-btn {
    background: #9ef800; /* Old browsers */
    background: -moz-linear-gradient(top, #9ef800 0%, #b0fd2a 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #9ef800 0%,#b0fd2a 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #9ef800 0%,#b0fd2a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9ef800', endColorstr='#b0fd2a',GradientType=0 ); /* IE6-9 */
    border: none;
    border-radius: 6px;
    box-shadow: 11.3px 11.3px 16px 0 rgba(0, 0, 0, 0.08);
    color: #016130;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.52;
    letter-spacing: -0.4px;
    margin-top: 45px;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
        background: #FFF;
    }
    @media screen and (max-width: $laptop) {
        margin-top: 20px;
        font-size: 14px;
    }
}
.footer {
    bottom: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    left: 0;
    position: absolute;
    padding: 25px 25px 15px 25px;
    width: 100%;
    @media screen and (max-width: $smartphone) {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 20px 15px;
        text-align: center;
    }
    @media screen and (max-height: $smartphone) {
        padding: 10px 25px;
    }
    @media screen and (max-width: $tablet) {
        position: relative;
    }
    @media screen and (max-width: $smartphone) {
        padding: 15px;
    }
    .poweredby {
        color: #339161;
        font-size: 12px;
        font-weight: 600;
        line-height: 2.46;
        text-transform: uppercase;
        @media screen and (max-height: $laptop) {
            font-size: 9px;
        }
        @media screen and (max-width: $smartphone) {
            margin-top: 15px;
            width: 100%;
        }
        a {
            color: #ffffff;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .social {
        @media screen and (max-width: $smartphone) {
            order: -1;
            width: 100%;
        }
        a {
            color: #FFF;
            display: inline-block;
            font-size: 22px;
            margin: 0 8px;
            text-decoration: none;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
            vertical-align: middle;
            @media screen and (max-height: $laptop) {
                font-size: 15px;
            }
            &:hover {
                opacity: .7;
            }
        }
    }
}
    
.ReactModalPortal {
    position: relative;
    z-index: 2;
    .modal-content {
        color: #555555;
        font-family: 'Montserrat', sans-serif;
        font-size: 17px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.37;
        padding: 0;
        opacity: .7;
        letter-spacing: normal;
        @media screen and (max-height: $laptop) {
            font-size: 17px;
        }
        .close {
            background: none;
            border: none;
            cursor: pointer;
            position: absolute;
            right: 7px;
            top: 7px;
            padding: 0;
            z-index: 1;
            .icon-close {
                font-size: 32px;
                .path1 {
                    &:before {
                        color: #004b25;
                    }
                }
            }
            &:hover {
                .icon-close {
                    font-size: 32px;
                    .path1 {
                        &:before {
                            color: #5a8a05;
                        }
                    }
                }
            }
        }
    }
    .ReactModal__Content {
        @media screen and (max-width: $smartphoneSmall) {
            max-height: 630px!important;
        }
    }
}
#iubenda_policy.iubenda_embed_policy .iub_base_container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
}
.page {
    left: 0;
    width: 100%;
}
// Animation
.fade-appear,
.fade-enter {
    opacity: 0;
    position: absolute;
    z-index: 1;
    transform: translate3d(100%, 0, 0);
    @media screen and (max-height: $tablet) {
        position: relative;
    }
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    position: absolute;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 300ms, opacity 1200ms;
    @media screen and (max-height: $tablet) {
        position: relative;
    }
}

.fade-exit {
    position: absolute;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    @media screen and (max-height: $tablet) {
        display: none;
    }
}

.fade-exit.fade-exit-active {
    opacity: 0;
    // position: absolute;
    transform: translate3d(-100%, 0, 0);
    transition: transform 300ms, opacity 1200ms;
    @media screen and (max-height: $tablet) {
        display: none;
    }
}