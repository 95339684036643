@import "../../../styles/base/vars";
.question-block {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 95vh;
    min-height: calc(100vh - 20px);
    height: 480px;
    padding: 10px 25px;
    text-align: center;
    @media screen and (max-width: $tablet) {
        display: block;
        min-height: unset;
        height: auto;
    }
    > div {
        width: 100%;
    }
    .question {
        max-width: 800px;
        margin: 0 auto;
        @media screen and (max-width: $laptop) {
            max-width: 700px;
        }
        .question-title {
            color: #ffffff;
            font-size: 40px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            padding: 0 10px;
            margin-bottom: 25px;
            @media screen and (max-width: $laptop) {
                font-size: 30px;
                margin-bottom: 15px;
                letter-spacing: 0.4px;
            }
            @media screen and (max-width: $smartphone) {
                font-size: 21px;
            }
        }
        .question-text {
            color: #FFF;
            font-size: 17px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.37;
            padding: 0 10px;
            opacity: .7;
            letter-spacing: normal;
            text-align: center;
            @media screen and (max-height: $laptop) {
                font-size: 13px;
            }
        }
    }
    .answers {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        text-align: center;
        margin-top: 40px;
        @media screen and (max-width: $laptop) {
            margin-top: 50px;
        }

        .answer-item {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0 10px;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
            margin: 0 0 25px 0;
            text-decoration: none;
            @media screen and (max-width: $laptop) {
                margin: 0 0 15px 0;
                padding: 0 8px;
            }
            @media screen and (max-width: $tablet) {
                padding: 0;
                width: 100%;
            }
            .item-wrap {
                border: solid 3px #FFF;
                border-radius: 5px;
                -webkit-box-shadow: 11.3px 11.3px 16px 0 rgba(0, 0, 0, 0.08);
                -moz-box-shadow: 11.3px 11.3px 16px 0 rgba(0, 0, 0, 0.08);
                box-shadow: 11.3px 11.3px 16px 0 rgba(0, 0, 0, 0.08);
                height: 215px;
                padding: 20px;
                width: 215px;
                @media screen and (max-width: $laptop) {
                    border: solid 2px #FFF;
                    height: 150px;
                    padding: 20px 15px;
                    width: 150px;
                }
                @media screen and (max-width: $tablet) {
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-flex-direction: row;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    -webkit-flex-wrap: nowrap;
                    -ms-flex-wrap: nowrap;
                    flex-wrap: nowrap;
                    -webkit-justify-content: space-between;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    -webkit-align-content: stretch;
                    -ms-flex-line-pack: stretch;
                    align-content: stretch;
                    -webkit-align-items: center;
                    -ms-flex-align: center;
                    align-items: center;
                    height: 80px;
                    padding: 10px 15px;
                    width: 100%;
                }
                .icon-wrap {
                    min-height: 60px;
                    @media screen and (max-width: $tablet) {
                        order: 1;
                        min-height: unset;
                    }
                    .icon {
                        color: #9ef800;
                        display: inline-block;
                        font-size: 90px;
                        margin: 10px 0 20px 0;
                        @media screen and (max-width: $laptop) {
                            font-size: 45px;
                            margin: 10px 0 15px 0;
                        }
                        @media screen and (max-width: $tablet) {
                            font-size: 60px;
                            margin: 0;
                        }
                        @media screen and (max-width: $smartphone) {
                            font-size: 45px;
                        }
                    }
                }
                
                .answer-text {
                    color: #FFF;
                    font-weight: 600;
                    line-height: 1.55;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-align-items: center;
                    -ms-flex-align: center;
                    align-items: center;
                    font-size: 14px;
                    height: 50px;
                    letter-spacing: normal;
                    text-align: center;
                    @media screen and (max-width: $laptop) {
                        font-size: 12px;
                    }
                    @media screen and (max-width: $tablet) {
                        font-size: 14px;
                        height: auto;
                        padding-right: 15px;
                        text-align: left;
                    }
                }
            }
        }
        &:hover {
            .answer-item {
                opacity: .4;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}