@font-face {
  font-family: 'EcommerceMatch';
  src:  url('../../assets/fonts/ecommercematch.eot?bbb193');
  src:  url('../../assets/fonts/ecommercematch.eot?bbb193#iefix') format('embedded-opentype'),
    url('../../assets/fonts/ecommercematch.ttf?bbb193') format('truetype'),
    url('../../assets/fonts/ecommercematch.woff?bbb193') format('woff'),
    url('../../assets/fonts/ecommercematch.svg?bbb193#ecommercematch') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"], .fa-prestashop, .fa-woocommerce {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'EcommerceMatch' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-woocommerce:before {
  content: "\e914";
}
.fa-prestashop:before {
  content: "\e915";
}
.icon-close .path1:before {
  content: "\e90f";
  color: rgb(224, 79, 95);
}
.icon-close .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-close .path3:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-check .path1:before {
  content: "\e912";
  color: rgb(50, 190, 166);
}
.icon-check .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cart:before {
  content: "\e90e";
}
.icon-arrow-dx:before {
  content: "\e900";
}
.icon-arrow-sx:before {
  content: "\e901";
}
.icon-cup .path1:before {
  content: "\e902";
  color: rgb(255, 142, 29);
}
.icon-cup .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 176, 35);
}
.icon-cup .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 209, 43);
}
.icon-cup .path4:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 176, 35);
}
.icon-cup .path5:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 142, 29);
}
.icon-cup .path6:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 109, 21);
}
.icon-cup .path7:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(255, 176, 35);
}
.icon-cup .path8:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 142, 29);
}
.icon-facebook-1:before {
  content: "\e90a";
}
.icon-instagram-1:before {
  content: "\e90b";
}
.icon-linkedin:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e90d";
}